<template>
  <div>
    <e-sidebar
      :title="edit ? $t('Editar Conta Gerencial') : $t('Nova Conta Gerencial')"
      :show="showSidebar"
      :fetching="fetching"
      :saving="saving"
      width="500px"
      @save="saveManagementAccountLocal"
      @hidden="cancel"
    >
      <template #content>
        <div>
          <FormulateForm
            ref="form"
            name="form"
          >
            <b-row>
              <b-col md="12">
                <FormulateInput
                  id="management-account-name"
                  v-model="formManagementAccount.name"
                  :label="$t('Nome')"
                  type="text"
                  class="required"
                  validation="required"
                />
              </b-col>
              <b-col md="12">
                <FormulateInput
                  id="management-account-type"
                  v-model="formManagementAccount.type"
                  :label="$t('Tipo')"
                  type="vue-select"
                  :placeholder="$t('Selecione')"
                  :options="accountTypes"
                  class="required"
                  validation="required"
                />
              </b-col>
              <b-col md="12">
                <FormulateInput
                  id="management-account-expense-type"
                  v-model="formManagementAccount.expenseType"
                  :label="$t('Tipo de Despesa')"
                  type="vue-select"
                  :placeholder="$t('Selecione')"
                  :options="expenseTypes"
                  :disabled="formManagementAccount.type !== 'Output'"
                  :class="{ required: formManagementAccount.type === 'Output' }"
                  :validation="formManagementAccount.type === 'Output' ? 'required' : ''"
                />
              </b-col>
              <b-col md="12">
                <FormulateInput
                  id="management-account-dre-classification"
                  v-model="formManagementAccount.dreClassificationId"
                  :label="$t('Classificação DRE')"
                  type="vue-select"
                  :placeholder="$t('Selecione')"
                  :options="dreClassificationsOptions"
                  class="required"
                  validation="required"
                />
              </b-col>
            </b-row>
          </FormulateForm>
        </div>
      </template>
    </e-sidebar>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { BRow, BCol } from 'bootstrap-vue'
import { ESidebar } from '@/views/components'
import { uploader } from '@/mixins'

export default {
  components: {
    BRow,
    BCol,
    ESidebar,
  },

  mixins: [uploader],

  props: {
    managementAccountId: {
      type: Number,
      default: null,
    },
    managementAccount: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      showSidebar: false,
      fetching: false,
      edit: false,
      saving: false,
      localManagementAccountId: null,
      localManagementAccount: {
        id: null,
        apiId: null,
        name: '',
        image: '',
        active: null,
      },
      imageLocal: [],
    }
  },

  computed: {
    ...mapGetters('pages/settings/financial/managementAccount', [
      'dreClassificationsOptions',
      'accountTypes',
      'expenseTypes',
    ]),
    ...mapState('pages/settings/financial/managementAccount/managementAccountMaintain', [
      'formManagementAccount',
    ]),
  },

  watch: {
    managementAccount(val) {
      this.localManagementAccount = val
    },
    managementAccountId(val) {
      this.localManagementAccountId = val
    },
    async localManagementAccountId(val) {
      if (val) {
        try {
          this.fetching = true
          await this.fetchManagementAccount(val)
        } catch (error) {
          this.showError({ error })
        } finally {
          this.fetching = false
        }
      } else {
        // this.managementAccount = {}
        this.cleanState()
      }
    },
  },

  methods: {
    ...mapActions('pages/settings/financial/managementAccount/managementAccountMaintain', [
      'fetchManagementAccount',
      'saveManagementAccount',
      'cleanState',
    ]),
    showCreate() {
      this.edit = false
      this.showSidebar = true
    },

    async show({ id, item } = {}) {
      if (!id && !item?.id) {
        this.showCreate()
      } else {
        if (id) this.localManagementAccountId = id
        if (item) this.localManagementAccount = item
        this.edit = true
        this.showSidebar = true
      }
    },

    async saveManagementAccountLocal() {
      this.$refs.form.showErrors()
      if (this.$refs.form.isValid) {
        try {
          this.saving = true
          await this.saveManagementAccount()
          this.showSuccess({
            message: this.$t(`${this.edit ? 'Atualização' : 'Inclusão'} realizada com sucesso`),
          })
          this.showSidebar = false
          this.$emit('save', this.formManagementAccount)
          this.$formulate.reset('form')
          this.cleanState()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.saving = false
        }
      } else {
        this.showInvalidDataMessage()
      }
    },

    cancel() {
      this.showSidebar = false
      this.localManagementAccountId = null
      this.$formulate.reset('form')
      this.cleanState()
    },
  },
}
</script>

<style></style>
