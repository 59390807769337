<template>
  <div>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="3">
            <FormulateInput
              id="name"
              v-model="filters.name"
              :label="$t('Nome')"
              :placeholder="$t('Nome')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="type"
              v-model="filters.type"
              type="vue-select"
              :label="$t('Tipo')"
              :placeholder="$t('Selecione')"
              :options="accountTypes"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="type"
              v-model="filters.expenseType"
              type="vue-select"
              :label="$t('Tipo de despesa')"
              :placeholder="$t('Selecione')"
              :options="expenseTypes"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="dre-classification"
              v-model="filters.dreClassification"
              type="vue-select"
              :label="$t('Classificação DRE')"
              :placeholder="$t('Selecione')"
              :options="dreClassificationsOptions"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="type"
              v-model="filters.active"
              type="vue-select"
              :label="$t('Status')"
              :placeholder="$t('Selecione')"
              :options="activeInactiveTypes()"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>
      <b-table
        ref="management-accounts-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('MANAGEMENT_ACCOUNT.NAME'), 'female')"
        :fields="fields"
        :items="managementAccounts"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(active)="row">
          <e-status-badge :status="row.item.active" />
        </template>

        <template #cell(action)="row">
          <e-grid-actions
            :is-active="row.item.active"
            :show-update="$can('Activate', 'ManagementAccount')"
            :show-delete="false"
            :show-activate="$can('Activate', 'ManagementAccount')"
            :show-deactivate="$can('Deactivate', 'ManagementAccount')"
            @update="showUpdateSidebar(row)"
            @activate="activateDeactivateManagementAccount(row)"
            @deactivate="activateDeactivateManagementAccount(row)"
          />
          <!-- TODO Inativar: activateInactivateAccount(row.item) -->
        </template>
      </b-table>
      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="management-accounts-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <management-account-sidebar
      ref="sidebar"
      @save="getData"
    />

    <fab
      v-if="$can('Create', 'ManagementAccount')"
      :main-tooltip="$t('Adicionar Conta gerencial')"
      @click="showCreateSidebar"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { EPageSizeSelector, EPaginationSummary } from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { statusTypes } from '@/mixins'
import EStatusBadge from '@/views/components/EStatusBadge.vue'
import EFilters from '@/views/components/EFilters.vue'
import EGridActions from '@/views/components/EGridActions.vue'
import ManagementAccountSidebar from './ManagementAccountSidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    fab,
    EStatusBadge,
    EFilters,
    EGridActions,
    ManagementAccountSidebar,
  },

  mixins: [statusTypes],

  data() {
    return {
      fetching: false,
    }
  },
  computed: {
    ...mapGetters('pages/settings/financial/managementAccount', [
      'dreClassificationsOptions',
      'accountTypes',
      'expenseTypes',
    ]),
    ...mapState('pages/settings/financial/managementAccount', [
      'managementAccounts',
      'paging',
      'sorting',
      'filters',
    ]),
    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Código'),
          key: 'id',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Nome'),
          key: 'name',
          sortable: true,
        },
        {
          label: this.$t('Tipo'),
          key: 'type',
          class: 'text-center',
          thStyle: { width: '100px' },
          formatter: value => this.$t(value),
          sortable: true,
        },
        {
          label: this.$t('Classificação DRE'),
          key: 'dreClassification.name',
          thStyle: { width: '210px' },
          sortable: true,
        },
        {
          label: this.$t('Status'),
          key: 'active',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
      ]
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/settings/financial/managementAccount', [
      'fetchManagementAccounts',
      'fetchDreClassifications',
      'setCurrentPage',
      'setPageSize',
      'resetFilters',
    ]),
    ...mapActions('common/managementAccounts', {
      commonFetchManagementAccounts: 'fetchManagementAccounts'
    }),
    ...mapActions('pages/settings/financial/managementAccount/managementAccountMaintain', [
      'switchManagementAccountActivation',
    ]),
    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await Promise.all([this.fetchManagementAccounts(), this.fetchDreClassifications()])
        } catch (error) {
          this.showError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.getData()
    },
    filterData() {
      this.getData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    updateAccount(row) {
      const { id } = row.item
      this.$router.push({ name: 'chart-of-account-maintain', params: { id } })
    },

    createAccount() {
      this.$router.push({ name: 'chart-of-account-add' })
    },

    showCreateSidebar() {
      this.$refs.sidebar.show()
    },

    async showUpdateSidebar(row) {
      this.$refs.sidebar.show({ id: row.item.id })
    },

    async activateDeactivateManagementAccount(row) {
      try {
        let action = row.item.active ? this.$t('inativação') : this.$t('ativação')
        const confirm = await this.confirm({
          text: this.$t('MANAGEMENT_ACCOUNT.ACTIVATE_CONFIRM', [action, row.item.name]),
        })
        if (confirm) {
          this.fetching = true
          await this.switchManagementAccountActivation(row.item)
          await this.commonFetchManagementAccounts()
          action = row.item.active ? this.$t('inativada') : this.$t('ativada')
          this.showSuccess({ message: this.$t('MANAGEMENT_ACCOUNT.ACTIVATE_SUCCESS', [action]) })
          this.getData()
        }
      } catch (error) {
        this.fetching = false
        this.showError({ error })
      }
    },
  },
}
</script>

<style></style>
